@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?gv5mrc");
  src: url("../fonts/icomoon.eot?gv5mrc#iefix") format("embedded-opentype"),
    url("../fonts/icomoon.ttf?gv5mrc") format("truetype"),
    url("../fonts/icomoon.woff?gv5mrc") format("woff"),
    url("../fonts/icomoon.svg?gv5mrc#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-database:before {
  content: "\e900";
}

.icon-cogs:before {
  content: "\e901";
}

.icon-gears:before {
  content: "\e901";
}

.icon-cloud:before {
  content: "\e903";
}

.icon-laptop:before {
  content: "\e902";
}
